
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import { commonModule, companyModule, installationsModule, itemsModule, partnersModule, tasksModule } from '@/store/modules/store-accessor'
  import { taskStatusToColor,  taskStatusToName, taskColor, getOptimalTextColor, templatesFilenameLanguage, formatOnlyDate, formatDateWithoutSeconds } from '@/helpers/data'
  import { TimeUtil } from '@/helpers/TimeUtil'
  import dayjs from 'dayjs';
  import FileSaver from 'file-saver';
  
@Component
export default class PartnersSideviewTasksTab extends Vue {
  @Prop() installation: Record<string, any>;

  completeWithJobButtonLoader = false;
  isDownloadingTaskPdf = false;

  tableOptions = {sortBy: ['task_status_id', 'due_at'], sortDesc: [false, false]};
  expandedItems: any[] = [];

  tableheaders: any [] = [
    { class:'font-weight-black', text: this.$t("title"), sortable: false, value: 'title'},
    { class:'font-weight-black', text: this.$t("address"), sortable: false, value: 'address' },
    { class:'font-weight-black', text: this.$t("item"), sortable: false, value: 'task_item' },
    { class:'font-weight-black', text: this.$t("partner"), sortable: false, value: 'assignee_id' },
    { class:'font-weight-black', text: this.$t("due_at"), sortable: true, value: 'due_at' },
    { class:'font-weight-black', text: this.$t("status"), sortable: true, value: 'task_status_id' },
    { class:'', text: '', value: 'is_periodic', width: '85px', sortable: false, align: 'right' }, // Move expand arrow to the right
    { class:'', text: '', value: 'data-table-expand' }, // Move expand arrow to the right
  ]

  mounted() {
    commonModule.getPersonnelPartnersCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
  }

  get tasks() {
    return partnersModule.currentPartner.tasks;
  }

  formatTaskDate(date: Date, all_day: string) {
    if (!date) {
      return "-";
    }
    else {
      if (all_day) {
        return formatOnlyDate(date);
      }
      return formatDateWithoutSeconds(date);
    }
  }

  formatDateTime (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  taskIsComplete(task: any) {
    if(task.task_status_id == 1) 
      return true;
    else
      return false;
  }

  dateToColor(task: Record<string, any>) {
      return taskColor(task)
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  statusToName(status: number) {
    return taskStatusToName(status)
  }

  statusToColor(status: number){
    return taskStatusToColor(status)
  }

  editTask (task: any) {
    commonModule.showModal({ name: 'edit-task-modal', payload: { task: task }})
  }

  completeTask (task: any) {
    commonModule.showModal({ name: 'complete-task-modal', payload: { task: task}})
  }

  completeTaskWithAppointment (task: any){
    commonModule.showModal({ name: 'add-appointment-modal', payload: { task_id: task.id, installation: this.installation, action_type_id: task.action_type_id, partner_id: task.partner_id }})
  }

  async completeTaskWithJob (task: any){
    this.completeWithJobButtonLoader = true;
    const assignee = partnersModule.currentPartner.info.is_business ?
                      `${partnersModule.currentPartner.info.entity_name} (${partnersModule.currentPartner.info.firstname} ${partnersModule.currentPartner.info.lastname})`
                      : `${partnersModule.currentPartner.info.firstname} ${partnersModule.currentPartner.info.lastname}`
    const installation = await installationsModule.getInstallationInfo(task.installation_id);
    commonModule.showModal({ name: 'add-job-modal', payload: { task_id: task.id, installation: installation, action_type_id: task.action_type_id, partner_id: task.partner_id, assignee: assignee, notes: task.notes }});
    this.completeWithJobButtonLoader = false;
  }

  deleteTask (task: any){
    commonModule.showModal({ name: 'delete-task-modal', payload: { task: task}})
  }

  showReminderLabel(task: Record<string, any>) {
    return task.title && task.title.length > 0 ? task.title : task.category && task.category.includes(1) ? this.$i18n.t("reminder_label") : '-' ;
  }

  async downloadTaskPdf(task: Record<string, unknown>) {
    this.isDownloadingTaskPdf = true;
    const utcOffset = dayjs().utcOffset();

    const data = { 'taskId': task.id, 'utcOffset': utcOffset, language: localStorage.getItem('lang')  };

    if(task.id) {
      const company = await companyModule.getCompanyInfo();
      const filename = `${templatesFilenameLanguage()}_${company.entity_name}_${task.tag_id}_task_${task.id}.pdf`.replace(/ /g, '_');
      const pdf = await  tasksModule.getTaskPdfExportAction(data);
      const file = new File([pdf], filename);
      FileSaver.saveAs(file);
    }
    this.isDownloadingTaskPdf = false;
  }
  
};
